import { Component, ChangeDetectionStrategy, ChangeDetectorRef, NgZone, Inject, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '@cue/admin-account';
import { AsyncPipe, DOCUMENT } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import { BehaviorSubject, fromEvent, merge, Subject, takeUntil, take } from 'rxjs';
import { HeaderActionsComponent } from '../header-actions/header-actions.component';
import { MenuComponent } from '../menu/menu.component';
import { ResponsiveService } from '@cue/admin-core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeaderActionsComponent, MenuComponent, RouterOutlet, AsyncPipe],
})
export class LayoutComponent implements OnDestroy {
  appTitle = 'CUE Admin';

  reset$: Subject<any> = new Subject();
  public menuCollapsed$ = new BehaviorSubject<boolean>(false);
  public headerHidden$ = new BehaviorSubject<boolean>(false);

  private _idleInterval;
  private _idleTime;
  private _show: NotificationRef;
  constructor(
    public translate: TranslateService,
    private router: Router,
    private notificationService: NotificationService,
    private zone: NgZone,
    private accountService: AccountService,
    @Inject(DOCUMENT) private document: Document,
    public responsiveService: ResponsiveService,
    private cdr: ChangeDetectorRef,
  ) {
    translate.addLangs(['en', 'cs']);
    translate.setDefaultLang('en');
    if (!localStorage.getItem('currentLanguage')) {
      const browserLang = translate.getBrowserLang();
      const newLang = browserLang.match(/en|cs/) ? browserLang : 'en';
      localStorage.setItem('currentLanguage', newLang);
    }
    translate.use(localStorage.getItem('currentLanguage'));
    translate.currentLang = localStorage.getItem('currentLanguage');
    this.clear();
    this.resetTime();
    this.zone.runOutsideAngular(() => {
      this.document.onload = this.resetTime.bind(this);
      merge(
        fromEvent(this.document, 'load'),
        fromEvent(this.document, 'mousemove'),
        fromEvent(this.document, 'mousedown'),
        fromEvent(this.document, 'touchstart'),
        fromEvent(this.document, 'touchmove'),
        fromEvent(this.document, 'click'),
        fromEvent(this.document, 'keydown'),
        fromEvent(window, 'scroll'),
      )
        .pipe(takeUntil(this.reset$))
        .subscribe((_) => {
          this.resetTime();
        });
    });

    responsiveService.isTablet().subscribe((result) => {
      this.menuCollapsed$.next(result);
      this.cdr.markForCheck();
    });
  }

  resetTime() {
    this._idleTime = 0;
    if (this._show) {
      this.zone.run(() => {
        this._show.hide();
        this._show = null;
      });
    }
    this.clear();
    this._idleInterval = setInterval(this.timerIncrement.bind(this), 10000); // kazdych 10 sec
  }

  clear() {
    if (this._idleInterval) {
      clearInterval(this._idleInterval);
    }
  }

  timerIncrement() {
    this._idleTime = this._idleTime + 10;
    if (this._idleTime > 60 * 20) {
      this.zone.run(() => {
        if (!this._show) {
          this._show = this.notificationService.show({
            content: this.translate.instant('portal.autoLogout'),
            hideAfter: 1000 * 60 * 30,
            position: { horizontal: 'center', vertical: 'top' },
            type: { style: 'warning', icon: true },
            animation: {
              type: 'fade',
              duration: 500,
            },
          });
        }
      });
    }
    if (this._idleTime > 60 * 30) {
      // vice nez 30 minut minutes
      this.zone.run(() => {
        if (this._show) {
          this.zone.run(() => {
            this._show.hide();
          });
        }
        this.clear();
        this.accountService.actionLogout().subscribe((_) => {
          this.router.navigateByUrl('/account/login');
        });
      });
    }
  }

  menuCollapsing(): void {
    this.menuCollapsed$.next(!this.menuCollapsed$.value);
  }

  ngOnDestroy(): void {
    this.reset$.next(null);
    this.reset$.complete();
    this.clear();
  }

  closeMobileMenuWithOverlay() {
    this.menuCollapsed$.pipe(take(1)).subscribe((menuCollapsed) => {
      this.responsiveService
        .isMobile()
        .pipe(take(1))
        .subscribe((isMobile) => {
          if (!menuCollapsed && isMobile) {
            this.menuCollapsing();
          }
        });
    });
  }
}
